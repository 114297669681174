import { cn } from "@/lib/utils";
import "@resources/css/overlay.css";

export default function CameraBox({
    children,
    clickable = false,
    className = "",
    width = "400px",
    height = "400px",
    hideOnMobile = true,
    ...rest
}) {
    return (
        <>
            <div
                id="content"
                {...rest}
                className={cn("camera-box flex flex-col relative", className)}
            >
                <div className={cn("overlay z-[1]", hideOnMobile ? "md:visible invisible" : "")}>
                    <div className="overlay-helper">
                        <div className="overlay-element top-left">
                            <span
                                id="overlay-top-left-text"
                                className="overlay-text !text-white !text-sm italic uppercase !font-nunito  font-semibold   whitespace-nowrap"
                            >
                                Rock My Prompt
                            </span>
                        </div>
                        <div className="overlay-element bottom-right"></div>
                    </div>
                </div>
                <div className="flex flex-col flex-wrap z-[2] md:px-[50px] px-[20px] md:py-[200px] py-[100px] justify-center items-center">
                    {children}
                </div>
            </div>
        </>
    );
}
