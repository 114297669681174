import ApplicationLogo from "@/Components/ApplicationLogo";
import { Toaster } from "@/Components/ui/sonner";
import GlobalLoader from "@/Modules/GlobalLoader";
import App from "@/Modules/TshirtCanvas";
import { Link, usePage } from "@inertiajs/react";
import AOS from "aos";
import { useEffect } from "react";
import { toast } from "sonner";

export default function AuthenticationLayout({ children, color = "#fff" }) {
    const { flash } = usePage().props;

    useEffect(() => {
        if (flash?.message) {
            toast.success(flash.message);
        } else if (flash?.error) {
            toast.error(flash.error);
        }
        return () => toast.dismiss();
    }, [flash]);

    useEffect(() => {
        AOS.init({ duration: 1200 });
        AOS.refresh();
    }, []);

    return (
        <div className="min-h-screen !h-screen !w-screen relative bg-gray-100 overflow-hidden">
            <App color={color} />
            <div className="absolute md:w-1/2 z-50 bg-primary/10 min-h-screen w-screen right-0 top-0 flex flex-col justify-center items-center overflow-hidden">
                {children}
            </div>
            <div className="absolute top-2 left-4 z-[99]">
                <Link href={route("home")} className="group">
                    <ApplicationLogo white className="h-12 w-fit group-hover:grayscale" />
                </Link>
            </div>
            <Toaster
                position="top-right"
                expand={false}
                closeButton
                richColors
            />
            <GlobalLoader />
        </div>
    );
}
