import React, { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
    useGLTF,
    useTexture,
    AccumulativeShadows,
    RandomizedLight,
    Environment,
    Center,
    Decal,
} from "@react-three/drei";
import { easing } from "maath";
import { useSnapshot } from "valtio";
import { state } from "@state/store";
import logo from '@public/logos/logo-square-white.png';

const App = ({ position = [-0.7, 0, 2.5], fov = 25, color = "#47c1bf" }) => (
    <Canvas
        className="h-screen z-[49] bg-canvasser"
        shadows
        camera={{ position, fov }}
        gl={{ preserveDrawingBuffer: true }}
    >
        <ambientLight intensity={0.5} />
        <Environment files="/christmas_photo_studio_04_1k.hdr" />
        <CameraRig>
            <Backdrop color={color} />
            <Center left>
                <Shirt color={color} />
            </Center>
        </CameraRig>
    </Canvas>
);

function Backdrop({ color }) {
    const shadows = useRef();
    useFrame((state, delta) =>
        easing.dampC(
            shadows.current.getMesh().material.color,
            color,
            0.25,
            delta,
        ),
    );
    return (
        <AccumulativeShadows
            ref={shadows}
            temporal
            frames={60}
            alphaTest={0.85}
            scale={10}
            rotation={[Math.PI / 2, 0, 0]}
            position={[0, 0, -0.14]}
        >
            <RandomizedLight
                amount={4}
                radius={9}
                intensity={0.55}
                ambient={0.25}
                position={[5, 5, -10]}
            />
            <RandomizedLight
                amount={4}
                radius={5}
                intensity={0.25}
                ambient={0.55}
                position={[-5, 5, -9]}
            />
        </AccumulativeShadows>
    );
}

function CameraRig({ children }) {
    const group = useRef();
    const snap = useSnapshot(state);
    useFrame((state, delta) => {
        easing.damp3(
            state.camera.position,
            [snap.intro ? -state.viewport.width / 4 : 0, 0, 2],
            0.25,
            delta,
        );
        easing.dampE(
            group.current.rotation,
            [state.pointer.y / 10, -state.pointer.x / 5, 0],
            0.25,
            delta,
        );
    });
    return <group ref={group}>{children}</group>;
}

function Shirt(props) {
    const snap = useSnapshot(state);
    const logoTexture = useTexture(logo);
    const { nodes, scene, materials } = useGLTF("/shirt_baked_2.glb");
    useFrame((state, delta) =>
        easing.dampC(materials.lambert1.color, '#0891b2', 0.25, delta)
    );
    return (
        <group>
            <mesh
                geometry={nodes.T_Shirt_male.geometry}
                material={materials.lambert1}
                position={[-1.3, -0.35, 0.6]}
                rotation={[1.7, 0, 0]}
                scale={1.2}
            >
                <Decal
                    position={[-0.42, 0.10, -0.40]}
                    rotation={[10, 0, 0]}
                    scale={0.14} // Adjusted scale
                    map={logoTexture}
                    flatShading={true}
                />
            </mesh>
        </group>
    );
}
useGLTF.preload("/shirt_baked_2.glb");

export default App;
